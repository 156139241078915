import React from "react";
import LogoImg from '../../img/logo/logo.png';
import LogoWebp from '../../img/logo/logo.webp';
import './Header.css';

const Header = () => {
    return (
        <header>
            <div className="container">
                <div className="header-wrapper">
                    <div className="store-name">
                        <a href="https://miamaya.online/">
                            <picture>
                                <source srcSet={LogoWebp} type="image/webp" />
                                <source srcSet={LogoImg} type="image/png" />
                                <img src={LogoImg} alt="Miamaya - fashion concept store" />
                            </picture>
                        </a>
                        <h1>Miamaya</h1>
                    </div>
                    <a href="https://miamaya.online/" className="btn-link">
                        https://miamaya.online/
                    </a>
                </div>
            </div>
        </header>
    );
};

export default Header;