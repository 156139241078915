import React from "react";
import "./IFrame.css";
import Iframe from "react-iframe";

const IFrame = ({ toggleIFrame, getCartToken }) => {
	let cartToken = JSON.parse(localStorage.getItem("cart")).basicInfo.token;

	return (
		<div className="iframe-wrapper">
			<span
				className="close-btn"
				onClick={() => {
					toggleIFrame();
					getCartToken(localStorage.hasOwnProperty("cart") ? JSON.parse(localStorage.getItem("cart")).basicInfo.token : "");
				}}>
            </span>

			<Iframe
				url={`https://miamaya.online/api/show-checkout?cartToken=${cartToken}`}
				width="100%"
				height="100%"
				frameBorder="0"
			/>
		</div>
	);
};

export default IFrame;
