import React from "react";
import Colors from './Colors';
import NonColors from './NonColors';

const onCartOpen = (e) => {
	e.stopPropagation();

	let cartContainer = document.getElementsByClassName('cart-container')[0];
	let cartOverlay = document.getElementsByClassName('cart-overlay')[0];

	if (!cartContainer.classList.contains('open')) {
		cartContainer.classList.add('open');
		cartOverlay.classList.add('visible');
	}
}

const ProductDetail = ({ selectedProduct, addToCart, changeOption }) => {
	let productOptions = selectedProduct.options;
	let colors = productOptions.filter(value => {
		return value.info.color;
	})[0];

	let notColors = productOptions.filter(value => {
		return !value.info.color;
	});

	return (
		<div className="product-detail">
			<div className="product-detail-lead">
				<p className="title">{selectedProduct.title}</p>
				<p className="price">
					{selectedProduct.price} {selectedProduct.currency}
				</p>

				<Colors 
					colorValues={colors}
					product={selectedProduct}
					changeOption={changeOption}
				/>

				<NonColors
					nonColorOptions={notColors}
					product={selectedProduct}
					changeOption={changeOption}
				/>

				<div className="product-description">
					<p className="description-title">Opis proizvoda</p>
					<div>
						<p>
							{selectedProduct.description}
						</p>
						<p>
							{selectedProduct.additional_desctription}
						</p>
					</div>
				</div>
			</div>

			<div className="product-detail-footer">
				<button type="button" className="add-to-cart" onClick={(e) => {addToCart(selectedProduct); onCartOpen(e)}}>
					Dodajte u korpu
				</button>
			</div>
		</div>
	);
};

export default ProductDetail;
