import React from "react";
import removeIcon from "../../img/remove-icon.png";

const CartItem = ({ cartItems, removeFromCart, changeQuantity }) => {
	const cartProducts = cartItems.map((item) => {
		return (
			<li className="cart-item" key={item.sku}>
				<div className="item-image">
					<picture>
						<source srcSet={item.image.webp} type="image/webp" />
						<source srcSet={item.image.url} type="image/jpg" />
						<img src={item.image.url} alt={item.image.alt} />
					</picture>
				</div>

				<div className="item-content">
					<div className="content-row">
						<h3 className="item-title">{item.title}</h3>
						<button
							type="button"
							className="remove-item"
							onClick={() => removeFromCart(item)}>
							<img src={removeIcon} alt="" />
						</button>
					</div>
					<div className="content-row">
						<div className="quantity">
							<button
								type="button"
								className="minus"
								data-quantity="minus"
								data-field="quantity"
								onClick={(e) => changeQuantity(e, item)}>
								<span>-</span>
							</button>
							<span className="quantity-num">
								{item.quantity}
							</span>
							<button
								type="button"
								className="plus"
								data-quantity="plus"
								data-field="quantity"
								onClick={(e) => changeQuantity(e, item)}>
								<span>+</span>
							</button>
						</div>
						<span className="times">x</span>
						<span className="item-price">{item.final_price}</span>
					</div>
				</div>
			</li>
		);
	});

	return <ul className="cart-products">{cartProducts}</ul>;
};

export default CartItem;