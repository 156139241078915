import React from "react";
import ProductDetail from "./ProductDetail";

const Products = ({ products, selectedProduct, onProductClick, addToCart, changeOption }) => {
	// products[0].sku === product.sku ? "active" : ""

	const renderedProducts = products.map((product) => {
		return (
			<div
				key={product.sku}
				className={product.sku === selectedProduct.sku ? "active" : ""}
				onClick={() => onProductClick(product)}>
				<picture>
					<source srcSet={product.image.webp} type="image/webp" />
					<source
						srcSet={product.image.url}
						type={`image/${product.image.ext}`}
					/>
					<img src={product.image.url} alt={product.image.alt} />
				</picture>
			</div>
		);
	});

	return (
		<div className="products-info">
			<div className="products-images">{renderedProducts}</div>
			<ProductDetail
				selectedProduct={selectedProduct}
				addToCart={addToCart}
				changeOption={changeOption}
			/>
		</div>
	);
};

export default Products;
