import React from "react";
import "./Cart.css";
import cartIcon from "../../img/cart-icon.png";
import CartItem from "./CartItem";

class Cart extends React.Component {
    onCartOpen(e) {
		e.stopPropagation();

		let cartContainer = document.getElementsByClassName(
			"cart-container"
		)[0];
		let cartOverlay = document.getElementsByClassName("cart-overlay")[0];

		if (!cartContainer.classList.contains("open")) {
			cartContainer.classList.add("open");
			cartOverlay.classList.add("visible");
		}
	}

	onCartClose(e) {
		e.preventDefault();
		e.stopPropagation();

		let cartContainer = document.getElementsByClassName(
			"cart-container"
		)[0];
		let cartOverlay = document.getElementsByClassName("cart-overlay")[0];

		if (cartContainer.classList.contains("open")) {
			cartContainer.classList.remove("open");
			cartOverlay.classList.remove("visible");
		}

	}

	getSubtotal() {
		return localStorage.hasOwnProperty('cart') ? JSON.parse(localStorage.getItem('cart')).basicInfo.priceSummary.final_price : '0,00 RSD'; 
	}

	getQuantity() {
		return localStorage.hasOwnProperty('cart') ? JSON.parse(localStorage.getItem('cart')).basicInfo.itemsCount : '0'; 
	}
	getItems() {
		return localStorage.hasOwnProperty('cart') ? JSON.parse(localStorage.getItem('cart')).items : []; 
	}

	closeIFrame() {
		if (this.props.iframeState === true) {
			this.props.toggleIFrame();
		}
	}
    
	render() {
		return (
			<div className="cart-wrapper">
				<div className="cart-overlay" onClick={this.onCartClose}></div>
				<div className="cart-button" onClick={(e) => {this.closeIFrame(); this.onCartOpen(e)}}>
					<span className="cart-count">{this.getQuantity()}</span>
					<img src={cartIcon} alt="" />
				</div>
				<div className="cart-container">
					<div className="cart-header">
						<h2>Korpa</h2>
						<button
							className="close-btn"
							type="button"
							onClick={this.onCartClose}></button>
					</div>

					<CartItem
						cartItems={this.getItems()}
                        removeFromCart={this.props.removeFromCart}
                        changeQuantity={this.props.changeQuantity}
					/>

					<div className="cart-footer">
						<div className="price-info">
							<span className="info">Ukupno</span>
							<span className="price">{this.getSubtotal()}</span>
						</div>
						<button
							onClick={() => this.props.toggleIFrame()}
							className={`btn-proceed ${
								this.getQuantity() === 0
									? "disabled"
									: ""
							}`}
							type="button">
							Započnite kupovinu
						</button>
					</div>
				</div>
			</div>
		);
	}
}

export default Cart;
