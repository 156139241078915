import React from "react";
import "./ImageItem.css";

const ImageItem = ({ image, onImageClick, iframeState, toggleIFrame }) => {
    const closeIframe = () => {
        if (iframeState === true) {
			toggleIFrame();
		}
    }

    return (
        <div className="image-card" onClick={() => {onImageClick(image); closeIframe()}}>
            <a href={image.link ? image.link  : void(0)}>
                <figure>
                    <img src={image.image.url} alt={image.image.alt} />
                    {!image.link ? <span className="cart-icon"></span> : ''}
                </figure>
            </a>
        </div>
    );
};

export default ImageItem;