import React, { Fragment } from "react";
import "./App.css";
import axios from "axios";
import jsonData from "./json/data.json";
import Header from "./components/Header/Header";
import ImageList from "./components/ImageList/ImageList";
import LeadImage from "./components/ImageList/LeadImage";
import Modal from "./components/Modal/Modal";
import Cart from "./components/Cart/Cart";
import IFrame from "./components/IFrame/IFrame";

const fetchHeaders = {
	"Content-Type": "application/json",
	"Access-Control-Allow-Origin": "*"
};

class App extends React.Component {
	state = {
		images: [],
		modalOpen: false,
		iframeOpen: false,
		selectedPost: null,
		selectedProduct: null,
		generalCurrency: jsonData.generalCurrency,
		fetchDomain: "https://miamaya.online/api/sr",
		fetchRoutes: {
			getInstaImages: "/get-instashop-images/",
			getCartToken: "/get-cart-token",
			addToCart: "/add-product-to-cart",
			removeFromCart: "/remove-product-from-cart",
			emptyCart: "/empty-cart",
			changeQuantity: "/change-quantity"
		},
		cartToken: null
	};

	constructor(props) {
		super(props);

		this.cartCount = this.state.addedToCart;
	}

	componentDidMount() {
		this.getInstaImages();
		this.getCartToken(localStorage.hasOwnProperty('cart') ? JSON.parse(localStorage.getItem('cart')).basicInfo.token : '');
	}

	generateFetchRoute = (routeUrl, urlId = '', params = {}) => {
		let url = this.state.fetchDomain + this.state.fetchRoutes[routeUrl] + (urlId.length > 0 ? urlId : '');
		let queryString = '';
		if(Object.entries(params).length > 0){
			let queryArray = [];
			for (var propName in params){
				queryArray.push(propName + '=' + params[propName]);
			}
			queryString = queryArray.join('&');
		}
		return url + (queryString.length ? '?' + queryString : '');
	};

	getInstaImages = (id = 'all') => {
		fetch(this.generateFetchRoute('getInstaImages', id),{
			method: 'get',
			headers: fetchHeaders
		})
		.then(response => response.json())
		.then(data => {
			this.setState({
				images: data.data,
			})
			localStorage.setItem('instaImages', JSON.stringify(data.data));
		});
	}

	getCartToken = (token = '') => {
		fetch(this.generateFetchRoute('getCartToken', '', token.length > 0 ? {cartToken: token} : {}), {
			method: 'get',
			headers: fetchHeaders
		})
		.then(response => response.json())
		.then(data => {
			localStorage.setItem('cart', JSON.stringify(data.data.cartJson));
			this.setState({
				cartToken: data.data.cartJson.basicInfo.token,
			})
		});
	}

	changeQuantity = (e, item) => {
		let params = {
			"productId": item.id,
			"sku": item.sku,
			"quantity": item.quantity + (e.currentTarget.classList.contains('plus') ? 1 : -1),
			"variantOptionValues": JSON.stringify(item.optionValues.map(value => {
				return value.name;
			}))
		};
		axios({
			method: 'post',
			url: this.generateFetchRoute('changeQuantity'),
			headers: fetchHeaders,
			data: {
				data: params,
				"cartToken": this.state.cartToken,
				"forApi": true
			}
		})
		.then(data => {
			localStorage.setItem('cart', JSON.stringify(data.data.cartJson));
			this.setState({
				cartToken: data.data.cartJson.basicInfo.token,
			})
		});
	}

	addToCart = (product) => {
		let params = {
			"productId": product.id,
			"sku": product.sku,
			"quantity": 1,
			"sourceOfAction": "page",
			"variantOptionValues": product.variants.filter(value => {
				return value.selected;
			})[0].option_values
		};
		axios({
			method: 'post',
			url: this.generateFetchRoute('addToCart'),
			headers: fetchHeaders,
			data: {
				data: params,
				"cartToken": this.state.cartToken,
				"forApi": true
			}
		})
		.then(data => {
			localStorage.setItem('cart', JSON.stringify(data.data.cartJson));
			this.setState({
				cartToken: data.data.cartJson.basicInfo.token,
			})
		});
	};

	removeFromCart = (cartItem) => {
		let params = {
			"productId": cartItem.id,
			"sku": cartItem.sku,
			"variantOptionValues": JSON.stringify(cartItem.optionValues.map(value => {
				return value.name;
			}))
		};
		axios({
			method: 'post',
			url: this.generateFetchRoute('removeFromCart'),
			headers: fetchHeaders,
			data: {
				data: params,
				"cartToken": this.state.cartToken,
				"forApi": true
			}
		})
		.then(data => {
			localStorage.setItem('cart', JSON.stringify(data.data.cartJson));
			this.setState({
				cartToken: data.data.cartJson.basicInfo.token,
			})
		});
	};
	
	onImageClick = (image) => {
		this.setState({
			modalOpen: !this.state.modalOpen,
			selectedPost: image,
			selectedProduct: image.products[0]
			// generalCurrency:image.products[0].currency
		});
	};

	onProductClick = (product) => {
		this.setState({
			selectedProduct: product
		});
	};

	changeOption = (optionId, isColor = true) => {
		let selectedProduct = this.state.selectedProduct;

		if (isColor) {
			selectedProduct.options.forEach(option => {
				if(option.info.color){
					option.values.forEach(optValue => {
						if(optValue.id !== optionId){
							optValue.selected = false;
						} else {
							optValue.selected = true;
						}
					});
				}
			});
		} else {
			selectedProduct.options.forEach(option => {
				if(!option.info.color && option.values.map(val => {
					return val.id;
				}).includes(optionId)){
					option.values.forEach(optValue => {
						if(optValue.id !== optionId){
							optValue.selected = false;
						} else {
							optValue.selected = true;
						}
					});
				}
			});
		}


		this.setState({
			selectedProduct: this.updateSelectedVariant(selectedProduct)
		});
	};

	updateSelectedVariant = (product) => {
		let selectedValues = this.getSelectedOptions(product);

		product.variants.forEach(variant => {
			if (JSON.stringify(variant.option_values) === JSON.stringify(selectedValues)) {
				variant.selected = true;
			} else {
				variant.selected = false;
			}
		});

		return product;
	}

	getSelectedOptions (product) {
		let values = [];

		product.options.forEach(option => {
			let selectedOptionValue = option.values.filter(value => {
				return value.selected;
			})[0].id;
			values.push(selectedOptionValue);
		});

		return values;
	}

	closeModal = () => {
		this.setState({ modalOpen: !this.state.modalOpen });
	};

	toggleIFrame = () => {
		if (this.state.iframeOpen === false) {
			this.setState({
				iframeOpen: !this.state.iframeOpen
			});
		} else {
			setTimeout(() => {
				this.setState({
					iframeOpen: !this.state.iframeOpen
				});
			}, 1000);
		}

		if (this.state.modalOpen === true) {
			this.setState({
				modalOpen: false
			});
		}

		let cartContainer = document.getElementsByClassName(
			"cart-container"
		)[0];
		let cartOverlay = document.getElementsByClassName("cart-overlay")[0];

		if (cartContainer.classList.contains("open")) {
			cartContainer.classList.remove("open");
			cartOverlay.classList.remove("visible");
		}

		setTimeout(() => {
			let iframeContainer = document.getElementsByClassName("iframe-wrapper")[0];
	
			if (!iframeContainer.classList.contains("open")) {
				iframeContainer.classList.add("open");
			} else {
				iframeContainer.classList.remove("open");
			}
		}, 100);
	}

	render() {
		return (
			<Fragment>
				<Header />
				<main>
					<div className="container">
						<ImageList
							images={this.state.images}
							onImageClick={this.onImageClick}
							toggleIFrame={this.toggleIFrame}
							iframeState={this.state.iframeOpen}
						/>
					</div>
				</main>
				{this.state.modalOpen === true ? (
					<Modal
						selectedPost={this.state.selectedPost}
						selectedProduct={this.state.selectedProduct}
						onProductClick={this.onProductClick}
						closeModal={this.closeModal}
						addToCart={this.addToCart}
						changeOption={this.changeOption}
					/>
				) : null}
				<Cart
					generalCurrency={this.state.generalCurrency}
					removeFromCart={this.removeFromCart}
					changeQuantity={this.changeQuantity}
					toggleIFrame={this.toggleIFrame}
					iframeState={this.state.iframeOpen}
				/>
				{this.state.iframeOpen === true ? (
					<IFrame 
						toggleIFrame={this.toggleIFrame}
						getCartToken={this.getCartToken}
					/>
				) : null}
			</Fragment>
		);
	}
}

export default App;
