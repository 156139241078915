import React from "react";

const Colors = ({ colorValues, product, changeOption }) => {
    const color = colorValues.values.map(value => {
        return (
            <label className="single-color" key={value.id}>
                <input
                    type="radio"
                    name={product.sku}
                    checked={value.selected}
                    onChange={() => changeOption(value.id)}
                />
                <picture>
                    <source srcSet={value.image.webp} type="image/webp" />
                    <source
                        srcSet={value.image.url}
                        type={`image/${value.image.ext}`}
                    />
                    <img src={value.image.url} alt={value.image.alt} />
                </picture>
            </label>
        );
    })

    return (
        <div>
            <p className="color-title">{colorValues.info.name}:</p>
            <div className="colors">
                {color}
            </div>
        </div>
    );
};

export default Colors;