import React from "react";
import ImageItem from "../ImageItem/ImageItem";
import "./ImageList.css";

const ImageList = ({ images, onImageClick, iframeState, toggleIFrame }) => {
	const renderedList = images.map((image) => {
		return (
			<ImageItem
				key={image.id}
                image={image}
				onImageClick={onImageClick}
				iframeState={iframeState}
				toggleIFrame={toggleIFrame}
			/>
		);
	});

	return <div className="images-wrapper">{renderedList}</div>;
};

export default ImageList;
