import React from "react";
import Select from 'react-select';

const NonColors = ({ nonColorOptions, product, changeOption }) => {
    const style = {
		control: (base) => ({
			...base,
			border: '1px solid #2b303b !important',
			boxShadow: '0 0 0 1px #2b303b !important',
			'&:hover': {
				border: '1px solid #2b303b !important'
			}
		}),
		menu: (base) => ({
			...base,
			borderRadius: 0,
			marginTop: 0
		}),
		menuList: (base) => ({
			...base,
			padding: 0,
		}),
		option: (base) => ({
			...base,
			backgroundColor: "#fff",
			color: '#080808',
			'&:hover': {
				backgroundColor: "#ccc"
			},
			'&:selected': {
				backgroundColor: "#ccc"
			},
			':active': {
				backgroundColor: "#ccc"
			}
		})
    }
    
    const nonColorOption = nonColorOptions.map(value => {
        let options = value.values.map(val => {
            return {
                value: val.id,
                label: val.name
            };
        });

        let selectedOptionValue = value.values.filter(val => {
            return val.selected;
        }).map(val => {
            return {
                value: val.id,
                label: val.name
            };
        })[0];

        return (
            <div key={value.info.id}>
                <p className="size-title">{value.info.name}:</p>
                <div className="sizes">
                    <Select name={product.sku} options={options} styles={style} defaultValue={selectedOptionValue} onChange={(e) => changeOption(e.value, false)} />
                </div>
            </div>
        );
    })


    return (
        <div>
            {nonColorOption}
        </div>
    );
};

export default NonColors;