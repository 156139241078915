import React from "react";
import "./Modal.css";
import Products from "./Products";

const Modal = ({ selectedPost, selectedProduct, onProductClick, closeModal, addToCart, changeOption }) => {
	return (
		<div className="modal-wrapper">
			<div className="modal-close-mobile">
				<button type="button" className="btn-back" onClick={() => closeModal()}>Go back</button>
			</div>

			<div className="modal-close" onClick={() => closeModal()}></div>

			<div className="modal-wrapper-inner">
				<div className="icon-close" onClick={() => closeModal()}>
					<span></span>
				</div>
				<figure>
					<img
						src={selectedPost.image.url}
						alt={selectedPost.image.alt}
					/>
					<span className="products-count">
						{selectedPost.products.length}
					</span>
				</figure>

				<Products
					products={selectedPost.products}
					selectedProduct={selectedProduct}
					onProductClick={onProductClick}
					addToCart={addToCart}
					changeOption={changeOption}
				/>
			</div>
		</div>
	);
};

export default Modal;
